import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Table, Button, Input, Select, Modal, Form, DatePicker, Space,Checkbox} from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import md5 from 'js-md5';
import {ExclamationCircleOutlined , SearchOutlined} from '@ant-design/icons';
import "./index.less"
import moment from 'moment';
const AreaContext = createContext();
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
  };

function LocalUserNew() {
    const { actUser, dispatchUser, form, onfinishNew } = useContext(AreaContext)
    const { state } = useContext(StateContext)
    const [selectUserType, setSelectUserType] = useState(101)
    const [userRole, setUserRole] = useState([])
    const formRef = React.createRef();
    const [defExpend,setDefExpend]=useState([]);
    const [myConf,setMyConf] = useState({})

    useEffect(() => {
        form.setFieldsValue(actUser);
        var userLogin = state.systemuser.list[0];
        var list = [];
        state.systemuser.list.filter(x=>x.parent===userLogin.id).map(m=>list.push(m.id+""));
        setDefExpend(list);
        if (window.CefSharp !== undefined) {
            window.CefSharp.BindObjectAsync("bound");
            let myConf =window.bound.getConfig();
            setMyConf(myConf);
        }
    }, [])


    return (
        <div >
            <Form
                form={form}
                ref={formRef}
                onFinish={onfinishNew}>
                <Form.Item
                    label="用户名称"
                    name="name"
                    rules={[{ required: true, message: '输入用户名称' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="用户账号"
                    name="account"
                    rules={[{ required: true, message: '输入用户账号' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="登录密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入登录密码',
                        },
                        {
                            pattern:(myConf&&myConf.isPwdL)?/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[\w\W]{8,}$/:/[\w\W]{2,}/,
                            // message:(myConf&&myConf.isPwdL)?'密码长度必须为8位以上,必须包含大小字母、数字、特殊字符':'密码长度必须为2位以上'
                            message:(myConf&&myConf.isPwdL)?'':'密码长度必须为2位以上'
                        }
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="重复登录密码"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请再输入一次登录密码',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不相同'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="用户类型"
                    name="type"
                    rules={[{ required: true, message: '用户类型' }]}>
                    <Select
                        // defaultValue={104}
                        style={{ width: '100%', flex: 1 }}
                        placeholder="用户类型"
                        onSelect={value=>{
                            // value===104?dispatchUser({ type:'update',user:{role:'301,304'}}):dispatchUser({type:'update',user: {role:'301,302,304,305,307,308,309,310'}});
                            setSelectUserType(value);
                            setUserRole(value===104?[{code:301,value:'基本权限'},{code:304,value:'数据导出'}]:
                            [
                                {code:301,value:'基本权限'},
                                {code:302,value:'报警管理'},
                                {code:304,value:'数据导出'},
                                {code:305,value:'参数设置'},
                                {code:307,value:'设备管理'},
                                {code:308,value:'区域管理'},
                                {code:309,value:'用户管理'},
                                {code:310,value:'平面图管理'},
                                {code:312,value:'更新报警原因'}
                            ])
                            formRef.current.setFieldsValue({
                                roles:value===104?[301]:[301,302,304,305,307,308,310]
                            })
                            dispatchUser({ type:'update',user:{role:value===104?'301':value===103?'301,302,304,305,307,308,310':'301,302,303,304,305,306,307,308,309,310,311,399'}})
                        }}
                    >
                        <Select.Option key={101} value={101}>管理员</Select.Option> 
                        <Select.Option key={103} value={103}>技术员</Select.Option> 
                        <Select.Option key={104} value={104}>操作员</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="用户权限"
                    name="roles"
                    dependencies={['type']}
                    rules={[{ required: true, message: '用户权限' }]}>
                    <Select
                        // defaultValue={104}
                        style={{ width: '100%', flex: 1 }}
                        mode="multiple"
                        placeholder="用户权限"
                        // onChange={value=>{
                        //    dispatchUser({ type:'update',user:{role:value.toString()}})
                        // }}
                    >
                       {
                        userRole.map(item => (
                            <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                        ))
                       }
                        
                    </Select>
                </Form.Item>
            </Form>

        </div>
    )
}
function LocalUserFix() {
    const { actUser, dispatchUser, form, onfinish } = useContext(AreaContext)
    const { state } = useContext(StateContext)
    const [userRole, setUserRole] = useState([])
    const formRef = React.createRef();
    useEffect(() => {
        form.setFieldsValue(actUser)
        setUserRole(actUser.type===104?[{code:301,value:'基本权限'},{code:304,value:'数据导出'}]:
        [
            {code:301,value:'基本权限'},
            {code:302,value:'报警管理'},
            {code:304,value:'数据导出'},
            {code:305,value:'参数设置'},
            {code:307,value:'设备管理'},
            {code:308,value:'区域管理'},
            {code:309,value:'用户管理'},
            {code:310,value:'平面图管理'}
        ])
    }, [])

    return (
        <Form
            form={form}
            ref={formRef}
            onFinish={onfinish}>
            <Form.Item
                label="用户名称"
                name="name"
                rules={[{ required: true, message: '输入用户名称' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                label="用户账号"
                name="account"
                rules={[{ required: true, message: '输入用户账号' }]}>
                <Input disabled />
            </Form.Item>
            <Form.Item
                    label="用户类型"
                    name="type"
                    rules={[{ required: true, message: '用户类型' }]}>
                    <Select
                        // defaultValue={104}
                        style={{ width: '100%', flex: 1 }}
                        placeholder="用户类型"
                        onSelect={value=>{
                            setUserRole(value===104?[{code:301,value:'基本权限'},{code:304,value:'数据导出'}]:
                            [
                                {code:301,value:'基本权限'},
                                {code:302,value:'报警管理'},
                                {code:304,value:'数据导出'},
                                {code:305,value:'参数设置'},
                                {code:307,value:'设备管理'},
                                {code:308,value:'区域管理'},
                                {code:309,value:'用户管理'},
                                {code:310,value:'平面图管理'}
                            ])
                            formRef.current.setFieldsValue({
                                roles:value===104?[301]:[301,302,304,305,307,308,310]
                            })
                        }}
                    >
                        <Select.Option key={101} value={101}>管理员</Select.Option> 
                        <Select.Option key={103} value={103}>技术员</Select.Option> 
                        <Select.Option key={104} value={104}>操作员</Select.Option>
                    </Select>
                </Form.Item>
            <Form.Item
                label="用户权限"
                name="roles"
                rules={[{ required: true, message: '用户权限' }]}>
                <Select
                    // defaultValue={104}
                    style={{ width: '100%', flex: 1 }}
                    mode="multiple"
                    placeholder="用户权限"
                    // onChange={value=>{
                    //     dispatchUser({ type:'update',user:{role:value.toString()}})
                    // }}
                >
                    {
                    userRole.map(item => (
                        <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                    ))
                    }
                    
                </Select>
            </Form.Item>
        </Form>
    )
}
function LocalUserManage() {
    const [form] = Form.useForm()
    const { state, dispatch } = useContext(StateContext)
    const [actUser, dispatchUser] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.user
        } else if (action.type === 'clear') {
            return {
                role: '',
                roles:[],
                password: "",
                phone: '',
                name: '',
                account: '',
                npw1: '',
                npw2: '',
                parent:'',
                type:''
            }
        }
        return state
    },[]);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)
    const [reSetPassWord, setReSetPassWord] = useState('')
    const [isreSetPassWord, setIsReSetPassWord] = useState(false);
    const [userParentRoles, setUserParentRoles] = useState('');
    const [showNotify, setShowNotify] = useState(false);
    const [todo_time, setTodo_time] = useState(moment().add(1,'years').format('yyyy-MM-DD'));
    const [myConf,setMyConf] = useState({})
    const [todo_Con,setTodo_Con] = useState(null);
    const [selUser,setSelUser] = useState(null);
    const [showUserSet,setShowUserSet] = useState(false)
    useEffect(() => {
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data })
                // setLocalUser(data.data);
            }
        })
        if (window.CefSharp !== undefined) {
            window.CefSharp.BindObjectAsync("bound");
            let myConf =window.bound.getConfig();
            setMyConf(myConf);
        }
    }, [])

    function  refresh() {
        setTimeout(() => {
            http.get("/v1/users", {}).then(data => {
                if (data.code === 0) {
                    let temp1 = data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh'));
                    dispatch({ type: 'systemuser', systemuser: temp1 })
                }
            })
        }, 2000);
    }
    const onfinish = (values) => {

        http.put("/v1/users/" + actUser.id, { ...actUser, ...values,role: values.roles.join(','),forbiden: values.forbiden? 1 : 0, }).then((data) => {
            http.get("/v1/users", {}).then(data => {
                if (data.code === 0) {
                    dispatch({ type: 'systemuser', systemuser: data.data })
                }
            })
        })

        setShowFixModel(false)
        dispatchUser({ type: 'clear', project_id: state.main.project_id })
    }
    const onfinishNew = (values) => {
        http.post("/v1/users", { ...values,password:md5(values.password), role: values.roles.join(','),parent:1 }).then((data) => {
            http.get("/v1/users", {}).then(data => {
                if (data.code === 0) {
                    dispatch({ type: 'systemuser', systemuser: data.data })
                }
            })
        })
        setShowNewModel(false)
    }
    const columns = [

        {
            title: '用户名称',
            dataIndex: 'name',
            key: 'id',
            width: 300,
            align: 'left',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='用户名称'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['name']
                    ? (record['name'].toString().toLowerCase().includes(value.toLowerCase()))
                    : '',
        },
        {
            title: '账号',
            dataIndex: 'account',
            key: 'account',
            align: 'center',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='账号'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['account']
                    ? (record['account'].toString().toLowerCase().includes(value.toLowerCase()))
                    : '',
        },
        {
            title: '用户类型',
            dataIndex: 'type',
            key: 'id',
            align: 'center',
            render: (text, record) => (
                <div>
                    {
                        record.type === 101 ? '管理员': record.type === 103 ? '技术员' :  '操作员'
                    }
                </div>
            ),
        },
        {
            title: '操作',
            dataIndex: 'operation',
            align: 'right',
            width: '400px',
            render: (text, record) => (
                <div>
                    {
                        (record.type === 102 || record.type === 103) &&
                        <Button size='small' style={{ marginLeft: '10px' }} type="primary" onClick={() => {
                            setShowNotify(true)
                            const user = state.systemuser.list.filter(x => { return x.id === record.id })
                            if (user.length > 0) {
                                dispatchUser({ type: 'update', user: user[0] })
                            }
                        }}>
                            添加提醒
                        </Button>
                    }
                        <Button size='small' style={{ marginLeft: '10px' ,borderRadius:10}} type="primary" onClick={() => {
                            setShowUserSet(true);
                            let selUser = state.systemuser.list.filter(x => { return x.id === record.id })[0];
                            setSelUser(selUser)
                            let to_do = selUser.todo;
                            if(to_do===''){
                                setTodo_Con(null)
                            }else{
                                setTodo_Con(JSON.parse(to_do))
                            }
                        }}>
                            用户设置
                        </Button>
                    <Button size='small' style={{ marginLeft: '10px' }} type="primary" onClick={() => {
                        setReSetPassWord(record.account);
                        setIsReSetPassWord(true);
                    }}>
                        重置密码
                    </Button>
                    <Button size='small' style={{ marginLeft: '10px' }} type="primary" onClick={() => {
                        setShowFixModel(true)

                        const user = state.systemuser.list.filter(x => { return x.id === record.id })
                        if (user.length > 0) {
                            const parent = state.systemuser.list.filter(x => { return x.id === user[0].parent })
                            if (parent.length > 0) {
                                setUserParentRoles(parent[0].role)
                            }
                            dispatchUser({ type: 'update', user: { ...user[0], roles: user[0].role.split(',').map(Number) } })
                        }
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={() => {
                        Modal.confirm({
                            centered: true,
                            title: `确定要删除用户<${record.name}>吗？`,
                            icon: <ExclamationCircleOutlined />,
                            content: `账号${record.account},删除之后将不可恢复！！`,
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/users/" + record.id, { data: record }).then((data) => {
                                    http.get("/v1/users", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'systemuser', systemuser: data.data });
                                        }
                                    })
                                })
                            },
                            onCancel() {
                            },
                        });
                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]

    return (
        <div style={{height:'100%',padding:10,backgroundColor:'#fff',position:'relative'}}>
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:10,border:'1px solid #ccc',borderRadius:5,padding:10}}>
                <div></div>
                <div >
                    <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 5 }}
                        onClick={() => {
                            setShowNewModel(true)
                            dispatchUser({ type: 'clear' })
                        }}>新建用户</Button>
                </div>
            </div>
            <div style={{position:'absolute',width:'100%',height:'100%'}}>
            <Table className='userTable' 
             bordered pagination={{showSizeChanger:true,hideOnSinglePage:true }} rowKey={record => record.id} columns={columns} size='small' 
             scroll={{y:state.resolution.height<800?426:state.resolution.height<1000?550:700}}
            dataSource={state.systemuser.list} />
            </div>
            
            <Modal maskClosable={false} title="新增用户"
                visible={showNewModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchUser({ type: 'clear'})
                }}>
                <AreaContext.Provider value={{ actUser, dispatchUser, form, onfinishNew }}>
                    <LocalUserNew />
                </AreaContext.Provider>
            </Modal>
            <Modal maskClosable={true} title="修改用户"
                visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}>
                <AreaContext.Provider value={{ actUser, dispatchUser, form, onfinish, userParentRoles }}>
                    <LocalUserFix />
                </AreaContext.Provider>
            </Modal>
            <Modal maskClosable={true} title="修改密码"
                destroyOnClose
                visible={reSetPassWord !== ''}
                closable={false}
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    form.submit();
                    setIsReSetPassWord(false);
                    
                }}
                onCancel={() => {
                    setReSetPassWord('')
                    setIsReSetPassWord(false);
                }}>
                <Form
                    name="basic"
                    form={form}
                    onFinish={values => {
                        http.post("/v1/userspassword", { who: state.main.account, password: md5(values.password), account: reSetPassWord, newpassword: md5(values.newpassword) }).then(data => {
                            if (data.code === 0) {

                            } else {
                                Modal.warning({
                                    title: data.code,
                                    content: data.msg,
                                });
                            }
                        })
                    }}
                >
                    <Form.Item
                        label="管理密码"
                        name="password"
                        rules={[{ required: true, message: '输入当前账号密码' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="新密码"
                        name="newpassword"
                        rules={[
                            { required: true, message: '输入被重置的新密码' },
                            {
                            pattern:(myConf&&myConf.isPwdL)?/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[\w\W]{8,}$/:/[\w\W]{2,}/,
                            // message:(myConf&&myConf.isPwdL)?'密码长度必须为8位以上,必须包含大小字母、数字、特殊字符':'密码长度必须为2位以上'
                            message:(myConf&&myConf.isPwdL)?'':'密码长度必须为2位以上'
                        }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="重复新密码"
                        name="newnewpassword"
                        dependencies={['newpassword']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '再次输入被重置的新密码',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newpassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('两次输入的密码不相同'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal maskClosable={true} title="增加提醒"
                visible={showNotify}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.post("/v1/userstodo", { account: actUser.account, todo: '到期年检',todo_time:todo_time}).then(res=>res.code!==0&&Modal.warning({
                        title: res.code,
                        content: res.msg,
                    }));
                    setShowNotify(false);
                }}
                onCancel={() => {
                    setShowNotify(false);
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}
            >
                <div>
                    <Input addonBefore="提醒内容"  allowClear defaultValue='到期年检' disabled={true} />
                    <div style={{flexFlow:'row',display:'flex',marginTop:'10px',alignItems:'center'}}>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>提醒日期</span>
                        <DatePicker onChange={(date,datestring)=>setTodo_time(datestring)}  defaultValue={moment().add(1,'years')}/>
                    </div>
                </div>    
            </Modal>
            <Modal maskClosable={true} title="用户设置"
                visible={showUserSet}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                width={800}
                onOk={() => {
                    let todoCon = todo_Con;
                    if(todoCon){
                        if(todoCon.isLock){
                            dispatch({type:'runningtime',runningtime:0})
                            dispatch({type:'runningtimeCount',runningtimeCount:todoCon.lockTimer?todoCon.lockTimer:0})
                        }else{
                            dispatch({type:'runningtime',runningtime:0})
                            dispatch({type:'runningtimeCount',runningtimeCount:0})
                        }
                        let pwdChange = {isChange:false,start:'2024-01-01 00:00:00',timer:30}
                        if(todoCon.isPwdChange){
                            todoCon={...todoCon,pwdStart:formatDateTimes(new Date())}
                            pwdChange.isChange = true;
                            pwdChange.start = todoCon.pwdStart;
                            pwdChange.timer = todoCon.pwdTimer; 
                        }
                       
                        dispatch({type:'pwdChange',pwdChange:pwdChange})
                    }
                    let todo =todoCon?JSON.stringify(todoCon):'';
                    if(selUser.id===state.main.id){
                        dispatch({type:'mainTodo',mainTodo:todo})
                    }
                    http.post("/v1/userstodo", { account: selUser.account, todo: todo,todo_time:'2100-01-01 00:00:00'}).then(res=>res.code!==0&&Modal.warning({
                        title: res.code,
                        content: res.msg,
                    }));
                    setShowUserSet(false);
                    refresh()
                }}
                onCancel={() => {
                    setShowUserSet(false);
                }}
            >
                <div>
                    <div className='flexRow'>
                        <Checkbox checked={todo_Con?todo_Con.isLock?true:false:false} onChange={(e)=>{
                            let con = todo_Con
                            if(con){
                                if(con.lockTimer){
                                    con ={...con,isLock:e.target.checked}
                                }else{
                                    con ={...con,isLock:e.target.checked,lockTimer:60}
                                }
                                
                            }else{
                                con ={isLock:e.target.checked,lockTimer:60}
                            }
                            setTodo_Con(con)
                        }}>待机锁屏</Checkbox>
                        <Input addonBefore="待机时间" addonAfter="分" style={{width:200,marginLeft:10}} value={todo_Con?todo_Con.lockTimer?todo_Con.lockTimer:'':''} 
                        onChange={e=>{
                            let val = e.target.value
                            let reg =/^([1-9]\d*)$/
                            let con = todo_Con
                            if(reg.test(val)){
                                if(con){
                                    con ={...con,lockTimer:parseInt(val)}
                                }else{
                                    con ={lockTimer:parseInt(val)}
                                }
                            }else if(val===''){
                                if(con){
                                    con ={...con,lockTimer:undefined}
                                }
                            }
                            setTodo_Con(con)
                        }}/>
                    </div>
                    <div className='flexRow'>
                        <Checkbox checked={todo_Con?todo_Con.isPwdReg?true:false:false} onChange={(e)=>{
                            let con = todo_Con
                            if(con){
                                if(con.pwdLen){
                                    con ={...con,isPwdReg:e.target.checked}
                                }else{
                                    con ={...con,isPwdReg:e.target.checked,pwdLen:6}
                                }
                                
                            }else{
                                con ={isPwdReg:e.target.checked,pwdLen:6}
                            }
                            setTodo_Con(con)
                        }}>密码策略</Checkbox>
                        <Input addonBefore="最小长度"addonAfter="位" style={{width:200,marginLeft:10}} value={todo_Con?todo_Con.pwdLen?todo_Con.pwdLen:'':''} 
                        onChange={e=>{
                            let val = e.target.value
                            let reg =/^[1-9]\d*$/
                            let con = todo_Con
                            if(reg.test(val)){
                                if(con){
                                    con ={...con,pwdLen:parseInt(val) }
                                }else{
                                    con ={pwdLen:parseInt(val)}
                                }
                            }else if(val===''){
                                if(con){
                                    con ={...con,pwdLen:undefined}
                                }
                            }
                            setTodo_Con(con)
                        }}/>
                        <Checkbox checked={todo_Con?todo_Con.isIncludeLetterS?true:false:false} onChange={(e)=>{
                            let con = todo_Con
                            if(con){
                                con ={...con,isIncludeLetterS:e.target.checked}
                            }else{
                                con ={isIncludeLetterS:e.target.checked}
                            }
                            setTodo_Con(con)
                        }} style={{marginLeft:10}}>大写字母</Checkbox>
                        <Checkbox checked={todo_Con?todo_Con.isIncludeSymbol?true:false:false} onChange={(e)=>{
                            let con = todo_Con
                            if(con){
                                con ={...con,isIncludeSymbol:e.target.checked}
                            }else{
                                con ={isIncludeSymbol:e.target.checked}
                            }
                            setTodo_Con(con)
                        }} style={{marginLeft:10}}>特殊符号</Checkbox>
                    </div>
                    <div className='flexRow'>
                        <Checkbox checked={todo_Con?todo_Con.isPwdChange?true:false:false} onChange={(e)=>{
                            let con = todo_Con
                            if(con){
                                con ={...con,isPwdChange:e.target.checked}
                                if(!con.pwdTimer){
                                    con ={...con,pwdTimer:30}
                                }
                            }else{
                                con ={isPwdChange:e.target.checked,pwdTimer:30}
                            }
                            setTodo_Con(con)
                        }}>密码到期</Checkbox>
                        <Input addonBefore="使用时间" addonAfter="天" style={{width:200,marginLeft:10}} value={todo_Con?todo_Con.pwdTimer?todo_Con.pwdTimer:'':''}
                        onChange={e=>{
                            let val = e.target.value
                            let reg =/^([1-9]\d*)$/
                            let con = todo_Con
                            if(reg.test(val)){
                                if(con){
                                    con ={...con,pwdTimer:parseInt(val)}
                                }else{
                                    con ={pwdTimer:parseInt(val)}
                                }
                            }else if(val===''){
                                if(con){
                                    con ={...con,pwdTimer:undefined}
                                }
                            }
                            setTodo_Con(con)
                        }}/>
                    </div>
                    {
                        selUser&&selUser.type>102&&
                        <div className='flexRow'>
                        <Checkbox checked={todo_Con?todo_Con.loginLockEnable?true:false:false} onChange={(e)=>{
                            let con = todo_Con
                            if(con){
                                con ={...con,loginLockEnable:e.target.checked,isLoginLock:false,loginErrCount:0}
                            }else{
                                con ={loginLockEnable:e.target.checked,isLoginLock:false,loginErrCount:0}
                            }
                            setTodo_Con(con)
                        }}>登录失败锁定功能</Checkbox>
                        <Checkbox checked={todo_Con?todo_Con.isLoginLock?true:false:false} onChange={(e)=>{
                            let con = todo_Con
                            if(con){
                                con ={...con,loginLockEnable:true,isLoginLock:e.target.checked,loginErrCount:e.target.checked?5:0}
                            }else{
                                con ={loginLockEnable:true,isLoginLock:e.target.checked,loginErrCount:e.target.checked?5:0}
                            }
                            setTodo_Con(con)
                        }}>锁定</Checkbox>
                        <div className='flexRow'>
                        <Checkbox checked={todo_Con?todo_Con.showHisModeNew?true:false:false} onChange={(e)=>{
                            let con = todo_Con
                            con ={...con,showHisModeNew:e.target.checked} 
                            setTodo_Con(con)
                        }}>数据模式（报警数据只上传相关测点的数据）</Checkbox>
                    </div>
                    </div>
                    }
                </div>    
            </Modal>
        </div>
    )
}

export default LocalUserManage